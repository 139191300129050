@import '../config/colors.css';

.cards-seccao {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 0 2rem 0;
  text-align: center;
}

.cards-texto {
  display: flex;
  padding-inline: 10rem;
}

.cards-button a {
  width: auto;
  height: 45px;
  line-height: 45px;
  margin-bottom: 5px;
  margin: 5px;
  margin-bottom: 5px;
  padding: 0 35px;
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: var(--lop-cards1);
}

.cards-button a:hover {
  background-color: var(--lop-cards2) !important;
}
