@import '../../../../config/colors.css';

.similar-container {
  display: flex;
  justify-content: center;
  height: 40rem;
  background-color: #ffffff;
  padding-top: 2rem;
  padding-top: 2rem;
}

.similar-inner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.similar-title {
  display: flex;
  height: 5rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.similar-line {
  display: block;
  width: 100%;
  height: 0.3px;
}
