@import '../../../config/colors.css';

.landing-page-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr;
  grid-auto-rows: 2fr;
}

.landing-page-section {
  width: 50vw;
  min-width: 250px;
  height: 50vh;
}

.landing-page-image-container {
  position: relative;
}

.landing-page-image {
  width: 100%;
  /*  
  opacity: 0.4; 
  */
}

.landing-page-text {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex; /* Use flexbox */
  flex-direction: column;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  transform: translate(-50%, -50%); /* Center the text */
  background-color: rgba(
    255,
    255,
    255,
    0.4
  ); /* Background color with opacity */
  padding: 10px;
  padding-top: 2rem;
  border-radius: 5px;
  text-align: center;
  min-height: 20vh;
}

.landing-page-text:hover {
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
}
