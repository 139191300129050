@import '../../../../config/colors.css';

.header-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: var(--lopDarkBackground);
  font-family: 'MyFontBook';
  align-items: baseline;
}

.left-side {
  display: flex;
  justify-content: flex-start;
  font-size: 2rem;
}
.right-side {
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
}
