@font-face {
  font-family: 'MyFontLight';
  src:
    local('MyFontLight'),
    url(../fonts/GothamRoundedLight.otf) format('opentype');
}
@font-face {
  font-family: 'MyFontBook';
  src:
    local('MyFontBook'),
    url(../fonts/GothamRoundedBook.otf) format('opentype');
}
@font-face {
  font-family: 'MyFontMedium';
  src:
    local('MyFontMedium'),
    url(../fonts/GothamRoundedMedium.otf) format('opentype');
}

@font-face {
  font-family: 'MyFontBold';
  src:
    local('MyFontBold'),
    url(../fonts/GothamRoundedBold.otf) format('opentype');
}

@font-face {
  font-family: 'MyFontLightItalic';
  src:
    local('MyFontLightItalic'),
    url(../fonts/GothamRoundedLightItalic.otf) format('opentype');
}
@font-face {
  font-family: 'MyFontBookItalic';
  src:
    local('MyFontBookItalic'),
    url(../fonts/GothamRoundedBookItalic.otf) format('opentype');
}
@font-face {
  font-family: 'MyFontMediumItalic';
  src:
    local('MyFontMediumItalic'),
    url(../fonts/GothamRoundedMediumItalic.otf) format('opentype');
}
@font-face {
  font-family: 'MyFontBoldItalic';
  src:
    local('MyFontBoldItalic'),
    url(../fonts/GothamRoundedBoldItalic.otf) format('opentype');
}

/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */

body {
  margin: 0;
  font-family:
    -apple-system,
    'MyFontRegular',
    'MyFontBook',
    'MyFontMedium',
    'MyFontBold',
    'MyFontRegularItalic',
    'MyFontBookItalic',
    'MyFontMediumItalic',
    'MyFontBoldItalic'
      /* , BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif */;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    'MyFontRegular',
    'MyFontBook',
    'MyFontMedium',
    'MyFontBold',
    'MyFontRegularItalic',
    'MyFontBookItalic',
    'MyFontMediumItalic',
    'MyFontBoldItalic'
      /* ,source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace */;
}

a {
  text-decoration: none !important;
}
* {
  font-family: 'MyFontBook';
  color: black;
}
.body100vh {
  min-height: 86.7vh;
}
