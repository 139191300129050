@import '../../config/colors.css';

.stars-container {
  display: flex;
}

.stars {
  font-size: 1.3rem;
}

.fa-star {
  color: gold !important;
  padding-left: 0.5rem;
}

.attribute-selector-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.attribute-selector {
  display: flex;
  margin-right: 1rem;
  padding-top: 0.8rem;
}

.attribute-selector-size {
  display: flex;
  flex-direction: row;
  background-color: var(--lop-store1);
  height: 2.5rem;
  padding: 0.5rem;
  margin-right: 1rem;
  border-radius: 0.6rem;
}

.attribute-selector-color {
  display: flex;
  background-color: var(--lop-store1);
  height: 50%;
  padding: 0.5rem;
  margin-right: 1rem;
  border-radius: 0.6rem;
}

.attribute-selector-size-text {
  display: flex;
  font-family: 'MyFontBook';
  font-weight: bolder;
  color: rgb(255, 255, 255);
  font-size: 2rem;
  align-items: center;
  justify-content: center;
}
