@import '../../../../config/colors.css';

.info-container {
  display: flex;
  justify-content: center;
  height: 30rem;
  background-color: #ffffff;
  padding-top: 2rem;
}

.info-inner-container {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 4;
  max-height: 30rem;
}

.info-title {
  display: flex;
  height: 5rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.info-text {
  display: flex;
  height: 50rem;
  padding-left: 3rem;
  overflow: auto;
}
.info-line {
  display: block;
  width: 100%;
  height: 0.1px;
}
