:root {
  --lopTextLight: #8c979e;
  --lopTextDark: #222f36;
  --lopLightBackground: #ffffff;
  --lopDarkBackground: #222f36;
  --lop1: #00ff00; /* cmyk(14, 0, 71, 0) */
  --lop2: #720017; /* cmyk(30, 100, 86, 36) */
  --lop3: #82ff24; /* cmyk(49, 0, 86, 0) */
  --lop4: #a6ffc9; /* cmyk(35, 0, 21, 0) */
  --lop5: #ff1c3d; /* cmyk(0, 89, 76, 0) */
  --lop6: #ff1208; /* cmyk(0, 93, 97, 0) */
  --lop7: #ffab2e; /* cmyk(0, 33, 82, 0) */
  --lop8: #ff080f; /* cmyk(0, 97, 94, 0) */
  --lop9: #ff4508; /* cmyk(0, 73, 97, 0) */
  --lop10: #ff2905; /* cmyk(0, 84, 98, 0) */
  --lop11: #862134; /* cmyk(30, 83, 73, 25) */
  --lop12: #742205; /* cmyk(31, 80, 97, 34) */
  --lop13: #f70552; /* cmyk(3, 98, 68, 0) */
  --lop14: #7400b4; /* cmyk(51, 100, 24, 7) */
  --lop15: #020474; /* cmyk(99, 98, 35, 30) */
  --lop16: #4ac4eb; /* cmyk(71, 23, 8, 0) */

  --lop-point1: #eb1208; /* cmyk(8, 93, 97, 0) */
  --lop-point2: #e80505; /* cmyk(8, 98, 98, 1) */
  --lop-point3: #d7000a; /* cmyk(13, 100, 96, 3) */
  --lop-point4: #fc1c3d; /* cmyk(1, 89, 76, 0) */
  --lop-point5: #c20509; /* cmyk(18, 98, 96, 7) */
  --lop-point6: #ff0f05; /* cmyk(0, 94, 98, 0) */
  --lop-point7: #f7050f; /* cmyk(3, 98, 94, 0) */

  --lop-cards1: #ffab2e; /* cmyk(0, 33, 82, 0) */
  --lop-cards2: #fffc00; /* cmyk(0, 1, 100, 0) */
  --lop-cards3: #ff2108; /* cmyk(0, 87, 97, 0) */
  --lop-cards4: #ff2100; /* cmyk(0, 87, 100, 0) */
  --lop-cards5: #ff3b08; /* cmyk(0, 77, 97, 0) */
  --lop-cards6: #ff1705; /* cmyk(0, 91, 98, 0) */

  --lop-store1: #021287; /* cmyk(99, 91, 32, 22) */
  --lop-store2: #1465e6; /* cmyk(92, 60, 9, 1) */
  --lop-store3: #4ac4eb; /* cmyk(71, 23, 8, 0) */
  --lop-store4: #020864; /* cmyk(99, 95, 38, 37) */
  --lop-store5: #0226b9; /* cmyk(99, 84, 22, 7) */
  --lop-store6: #80054b; /* cmyk(00, 96, 41, 50) */

  --lop-led1: #dbff4a; /* cmyk(14, 0, 71, 0) */
  --lop-led2: #b8ed00; /* cmyk(28, 7, 100, 0) */
  --lop-led3: #689d02; /* cmyk(54, 31, 99, 11) */
  --lop-led4: #79b600; /* cmyk(50, 25, 100, 5) */
  --lop-led5: #a3de00; /* cmyk(36, 13, 100, 0) */
  --lop-led6: #8ec500; /* cmyk(43, 21, 100, 2) */

  --lop-retail1: #2ca202; /* cmyk(80, 27, 99, 13) */
  --lop-retail2: #48d700; /* cmyk(71, 14, 100, 2) */
  --lop-retail3: #8cff36; /* cmyk(45, 0, 79, 0) */
  --lop-retail4: #48d700; /* cmyk(71, 14, 100, 2) */
  --lop-retail5: #207904; /* cmyk(83, 35, 98, 27) */
  --lop-retail6: #1c5406; /* cmyk(81, 42, 96, 43) */

  --lop-health1: #57c99e; /* cmyk(66, 21, 38, 0) */
  --lop-health2: #99f0c9; /* cmyk(40, 6, 21, 0) */
  --lop-health3: #5ce3a1; /* cmyk(64, 11, 37, 0) */
  --lop-health4: #34a17b; /* cmyk(78, 32, 48, 7) */
  --lop-health5: #1c755d; /* cmyk(86, 42, 54, 21) */
  --lop-health6: #135845; /* cmyk(89, 48, 59, 34) */
}
