@import '../../../../../../config/colors.css';

.store-icon-selector {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 5rem;
  justify-content: center;
}

.store-icon-selector-image {
  display: flex;
  width: auto;
  height: 3rem;
  border-radius: 3rem;
  justify-content: center;
  margin: auto;
}

.store-icon-selector-text {
  display: flex;
  font-size: 0.5rem;
  font-weight: 800;
  text-align: center;
  justify-content: center;
}
