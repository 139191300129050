@import '../../config/colors.css';

.mac-book-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--lopDarkBackground);
  width: 100%;
}

.mac-book-body-video-container {
  width: 100%;
  background-color: black;
}

.mac-book-body-table {
  align-items: center;
  width: 100%;
  height: 87.3vh;
}
