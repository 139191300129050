@import '../config/colors.css';

.servicos-seccao {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: var(--lopTextDark);
}

.servicos-text-header .h2 {
  display: flex;

  justify-content: center;
  color: var(--lopTextDark);
}

.servicos-text {
  display: flex;
  max-width: 50rem;
  align-self: center;
  text-align: left;
  color: var(--lopTextDark);
}
