@import '../../../config/colors.css';

.store-product {
  display: flex;
  flex-direction: column;
  color: var(--lopDarkBackground);
  padding-top: 7rem;
  padding-inline: 25rem;
}

.fa-shopping-bag,
.fa-heart {
  color: var(--lop-store3) !important;
  padding-right: 1.8rem;
  font-size: 2rem;
}
