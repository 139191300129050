@import '../../../../../config/colors.css';

.store-info-header {
  display: grid;
  grid-template-columns: 2fr minmax(250px, 1fr);
  height: 5rem;
  padding-inline: 4rem;
}

.store-info-header-left,
.store-info-header-right {
  display: flex;
  align-items: center;
  height: 10rem;
  justify-content: center;
}

.store-info-header-jumbotron-container {
  display: flex;
  padding: 2rem;
  text-align: start;
}
.store-info-header-jumbotron-logo {
  display: flex;
  width: auto;
  height: 100%;
  align-self: center;
}

.store-info-header-jumbotron-text {
  display: flex;
  padding: 2rem;
  text-align: start;
  font-size: 3rem;
  font-weight: bold;
}

.store-info-header-contact-image-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.store-info-header-contact-image {
  display: flex;
  height: 6rem;
  border-radius: 3rem;
}

.store-info-header-contact-text {
  display: flex;
  font-size: 1rem;
  padding: 2rem;
  color: var(--lopTextDark);
  text-align: left;
}
