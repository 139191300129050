@import '../config/colors.css';

.contactos-seccao {
  display: flex;
  padding: 4rem 10rem 2rem 10rem;
  text-align: center;
  color: var(--lopDarkBackground) !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.contactos-texto-header .h2 {
  flex-direction: column;
  display: flex;

  justify-content: center;
}

.contactos-container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
}

.contactos-info {
  flex: 2;
  color: white !important;
  text-align: left;
}

.contactos-separator {
  margin-top: 5rem;
}

.contactos-item {
  margin-top: 10px;
}

.contactos-button a {
  width: auto;
  height: 45px;
  line-height: 45px;
  margin-bottom: 5px;
  margin: 5px;
  padding: 0 35px;
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: var(--lop16);
}

.contactos-button a:hover {
  background-color: var(--lop15) !important;
}

.contactos-widget {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ### F O R M  ### */

.contactos-form {
  flex: 2;
  min-width: 300px;
  margin-left: 5rem;
  margin-right: 5rem;
  border-radius: 2%;
  background-color: white;
}

.contactos-text-box-area {
  width: 100%;
  margin: 2rem;
  box-sizing: border-box;
  border: 0.2px solid var(--lopTextLight);
  border-radius: 4px;
  font-size: 1rem;
}

.contactos-text-box-area-label {
  color: var(--lopTextDarkt) !important;
  padding-left: 1rem;
  width: 100%;
  height: 40px;
  font-size: 1rem !important;
}

.contactos-text-box-area-text-area {
  color: var(--lopTextDarkt) !important;
  padding-left: 1rem;
  width: 100%;
  height: 140px;
  font-size: 1rem !important;
}

.contactos-check-box {
  color: var(--lopTextDarkt) !important;
  padding-left: 2rem;
  font-size: medium;
  align-self: start;
  text-align: justify;
}

.contactos-link {
  color: black !important;
}

.contactos-button-box {
  color: var(--lopTextDarkt) !important;
  padding-right: 0rem;
  font-size: medium;
  align-self: flex-end;
  text-align: justify;
}

.contactos-send-button {
  color: white !important;
  font-size: medium;
  width: 150px;
  height: 50px;
  background-color: var(--lop15);
  border: 0;
}

.contactos-send-button:disabled {
  color: white !important;
  font-size: medium;
  width: 150px;
  height: 50px;
  border: 0;
  background-color: var(--lopLightBackground);
}
