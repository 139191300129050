@import '../config/colors.css';

.parceiros-seccao {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.parceiros-text-header .h2 {
  display: flex;

  justify-content: center;
  color: var(--lopTextDark);
}

.parceiros-text {
  display: flex;
  padding-inline: 10rem;
}

.slide-show-image-slider {
  overflow: hidden;
  background-color: #ffffff !important;
}

.slide-show-slider-content {
  display: flex;
  padding-bottom: 2rem;
}

.slide-show-slide {
  height: 8rem;
  width: 8rem;
  padding-left: 2rem;
  object-fit: cover;
}

.parceiros-button a {
  display: flex;
  width: auto;
  height: 45px;
  line-height: 45px;
  margin-bottom: 5px;
  margin: 5px;
  padding: 0 35px;
  display: inline-block;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: var(--lop7);
  color: white !important;
}

.parceiros-button a:hover {
  background-color: var(--lop14) !important;
}
