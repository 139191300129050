@import '../../config/colors.css';

.back-office-container {
  display: flex;
  flex-direction: column;
  padding: 3.5rem;
}

.back-office-user-info {
  display: flex;
  min-width: 250px;
  height: 2rem;
  justify-content: center;
  align-items: center;

  font-size: 1.2rem;
  justify-content: flex-end;
}

.back-office-table-container {
  display: flex;
  flex-direction: column;
  min-height: 80%;
  font-size: 1.2rem;
  justify-self: flex-start;
}
