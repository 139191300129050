@import '../config/colors.css';
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  overflow: hidden;
  overflow: hidden;
  pointer-events: auto; /* This allows the overlay to capture mouse events */
}

.login-modal {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  width: 350px;
}

.login-modal-header {
  display: flex;
  flex-direction: column;
  background-image: url(https://www.nicepng.com/png/full/4-48532_wave-png-transparent-image-blue-wave-vector-png.png);
  background-position: 0% calc(50% - 50px);
  background-repeat: no-repeat;
  background-size: cover;
  height: 15rem;
}

.login-text {
  display: flex;
  color: rgb(248, 246, 246);
  padding-left: 3rem;
  font-family: 'MyFontBook';
  font-size: 3rem;
  height: 3rem;
}

.form-text {
  display: flex;
  background: rgba(255, 255, 255, 0.774);
  border: 1px solid lightgray;
  cursor: pointer;
  font-size: 1.5em;
  align-self: flex-end;
  width: 100%;
  border-radius: 0.2rem;
}
.form-group {
  margin-bottom: 15px;
  padding: 1rem;
}

.close-btn {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  align-self: flex-end;
  padding: 1rem;
  color: rgb(248, 246, 246);
}

.btn-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
}

.btn {
  border: 1px solid;
  border-radius: 1.2rem;
  font-size: 1rem;
  cursor: pointer;
  color: azure;
  font-family: 'MyFontBold';
}

.btn-login {
  padding: 0.8rem 1rem;
  background-color: var(--lop15);
}

.btn-signin {
  padding: 0.3rem 1rem;
  background-color: var(--lop16);
}

.forget-password {
  padding-bottom: 1rem;
  cursor: pointer;
}

/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 75%; /* Set width to 75% of the screen */
  max-width: 600px; /* Maximum width */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #888;
}
