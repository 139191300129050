.slider-app {
  display: flex;
  height: 30rem;
  align-items: flex-start;
  justify-content: flex-start;
}

.slider-container {
  display: flex;
  overflow: scroll;
  scrollbar-width: none;
  /* Add the following lines for smooth scrolling */
  scroll-behavior: smooth;
  transition: scroll 1s ease-in-out;
  min-width: 80%;
}

.back-front-btn {
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: #dddddd;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  margin: 5px;
  align-items: center;
  justify-content: center;
}

.button-show {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.button-show-front {
  opacity: 0;
}

.slider-container:hover + .button-show-front,
.button-show-front:hover {
  opacity: 1;
}
.slider-container:hover + .button-show-back,
.button-show-back:hover {
  opacity: 1;
}

.slider-app ::-webkit-scrollbar {
  display: none;
}

.button-show-back {
  opacity: 0;
}

.button-show-back:hover {
  opacity: 1;
}

.hide {
  visibility: hidden;
}
