@import '../../../../config/colors.css';

.body-inner-container {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  justify-content: center;
  height: 30em;
  padding-top: 2rem;
  text-align: center;
  color: var(--lopDarkBackground);
}

.body-inner-left-side {
  display: flex;
  max-height: 30rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: 2rem;
}

.body-inner-left-side-designation {
  display: flex;
  justify-content: center;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.3rem;
  margin-bottom: 2rem;
  height: 4rem;
}

.body-inner-left-side-price {
  display: flex;
  height: 2rem;
  font-size: 1.1rem;
  min-width: 100%;
  justify-content: flex-end;
  padding: 0;
}

.body-inner-left-colors {
  display: flex;
  height: 8rem;
  min-width: 100%;
  cursor: pointer;
}

.body-inner-left-button {
  display: flex;
  height: 4rem;
  width: 15rem;
  background-color: var(--lop-store2);
  justify-content: center;
  border-radius: 1rem;
  align-items: center;
  margin-top: 2rem;
  cursor: pointer;
}

.body-inner-left-info {
  display: flex;
  height: 15rem;
  min-width: 100%;
  padding-top: 1rem;
}

.body-inner-right-side {
  display: flex;
  max-height: 30rem;
  justify-content: center;
}

.body-image {
  display: flex;
  object-fit: contain;
  align-content: center;
  max-height: 30rem;
  max-width: 100%;
  align-items: center;
}

.body-text {
  color: var(--lop-store2);
  font-family: 'MyFontBook';
  cursor: pointer;
}

.normal-text {
  font-size: 0.8rem;
  text-align: start;
}
