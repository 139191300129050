@import '../../config/colors.css';

.lop-nav-bar {
  font-family: 'MyFontBook';
  background-color: rgba(255, 255, 255, 0.66);
  position: fixed;
  width: 100%;
  height: 2.5rem;
  z-index: 1000;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensures the navbar stays on top */
  visibility: visible;
  opacity: 1;
  transition:
    visibility 0.3s,
    opacity 0.3s linear;
}

.lop-nav-bar-store {
  height: 4rem;
}

.lop-nav-navbar.smooth-hidden {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0.3s,
    opacity 0.3s linear;
}
.lop-nav-navbar-button {
  height: 1rem;
  background-color: transparent;
  color: black;
  border: 0px;
  font-size: 0.8rem;
  padding-right: 1rem;
  cursor: pointer;
}

.lop-nav-navbar-button:hover {
  display: flex;
  color: rgb(94, 94, 94);
  border-bottom: 2px solid rgb(50, 172, 1);
  font-size: 0.9rem;
  padding: 0.1rem;
}

.lop-nav-top-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2.3rem;
  align-items: center;
  font-size: 1rem;
  background-color: transparent !important ;
  border: 0px !important;
}

.lop-nav-top-logo-image {
  width: 28px;
  padding-top: 0.3rem;
  margin-right: 1rem;
}

.store-text-area {
  width: 100%;
  height: 1.5rem;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 0.3px solid #000000;
  border-radius: 4px;
  background-color: white;
  font-size: 1.3rem;
  resize: none;
  position: relative;
  z-index: 500;
}

.lop-nav-bottom-header {
  display: flex;
  height: 4rem;
  background-color: 'red' !important;
  align-items: flex-start;
  background-color: transparent;
}
