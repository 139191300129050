@import '../../config/colors.css';

.footer-seccao {
  display: flex;
  height: 5rem;
  width: 100%;
  align-items: center;
  text-align: center;
  max-height: 5rem;
  display: inline-block;
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: #f5f5f5;
}

.links {
  color: var(--lop16) !important ;
}

.footer-text {
  font-family: 'MyFontBook';
  font-size: 0.8rem;
  padding: 0px;
}

.footer-politicas {
  font-family: 'MyFontBook';
  font-size: 0.6rem;
  display: inline-block;
  padding-bottom: 1rem;
}
