@import '../config/colors.css';

.mister-gadget-seccao {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 0 2rem 0;
  text-align: center;
  justify-content: center;
}

.mister-gadget-text-header .h3 .h2 .h4 {
  display: flex;
  justify-content: center;
  padding-inline: 10rem;
}

.mister-gadget-item {
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  justify-content: center;
}

.mister-gadget-item-container {
  padding: 2rem;
}
