@import '../../../../config/colors.css';

.store-section {
  min-height: 100%;
  padding-top: 5rem;
  padding-inline: 2rem;
}

.store-section-text {
  font-weight: bolder;
  font-size: 1.6rem;
  color: var(--lop-store1);
  padding-left: 3.8rem;
}
.store-section-secondary-text {
  font-weight: bolder;
  font-size: 1rem;
  color: var(--lopTextLight);
}

.store-section-product {
  display: flex;
  flex-direction: row;
  height: 32rem;
}

.store-section-product-column {
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  height: 100%;
}
