.list-app {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  flex-wrap: wrap;
}

.list-container {
  display: flex;
  width: 100%;
}

.list-app ::-webkit-scrollbar {
  display: none;
}
