@import '../config/colors.css';

.projectos-seccao {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 0 2rem 0;
  text-align: center;
  color: var(--lopDarkBackground);
}

.projectos-button a {
  display: flex;
  width: auto;
  height: 45px;
  line-height: 45px;
  margin-bottom: 5px;
  margin: 5px;
  padding: 0 35px;
  display: inline-block;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: var(--lop5);
  color: white !important;
}

.projectos-button a:hover {
  background-color: var(--lop14) !important;
}

.projetos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-inline: 10rem;
  padding-bottom: 2rem;
}

.projectos-item {
  display: flex;
  justify-content: center;
  width: 20rem;
  max-height: 20rem;
  padding: 0.1rem;
}

.projectos-texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-wrap: wrap;
  color: white !important;
  font-size: 1rem;
  text-align: center;
  line-height: 0px;
  width: 100%;
  padding: 2rem 0 2rem 0;
}

.projectos-texto-item-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-wrap: wrap;
  color: white !important;
  font-size: 1rem;
  text-align: center;
  line-height: 2rem;
  width: 100%;
}

.projectos-lop-logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-inline: 10rem;
  padding-bottom: 2rem;
}
.projectos-lop-logo {
  width: 10rem;
  height: 5rem;
  padding: 2rem;
  padding-bottom: 10rem;
}
