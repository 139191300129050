@import '../../../config/colors.css';

.store-panel-base {
  background-color: #ffffff;
  width: 25rem;
}
.store-panel-type-container {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  /*  background-color: #974e4e;*/
  z-index: 1;
  height: 8rem;
}
.store-panel-type-text-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  color: rgb(37, 26, 26);
  /*  background-color: #d31f1f;*/
}
.store-panel-type-text-container {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  color: rgb(37, 26, 26);
  /*  background-color: #d31f1f;*/
}
.store-panel-type-button-container {
  display: flex;
  justify-content: center;
  width: 30%;
  color: rgb(37, 26, 26);
  /*  background-color: #1fd32e;*/
}

.store-panel-type-value {
  display: flex;
  padding-left: 2rem;
  align-self: center;
}

.store-panel-type-payments {
  align-self: flex-end;
  padding-right: 2rem;
  padding-left: 2rem;
  justify-content: flex-end;
}

/* LIST */

.store-panel-type-text {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  align-items: flex-end;
  color: rgb(37, 26, 26);
}

.store-panel-product {
  max-height: 100%;
  margin: 2rem;
  min-width: 20rem;
  max-width: 20rem;
  border-radius: 2rem;
  background-color: #ffffff;
  transition: all 0.5s linear;
}

.store-panel-product-text {
  font-family: 'MyFontBook';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  font-size: 1rem;
  color: var(--lopTextDark);
  padding-left: 1rem;
  background-color: transparent;
}

.store-panel-product-text-price {
  font-weight: bold;
  font-size: 1rem;
  color: black;
  padding-left: 2rem;
  background-color: transparent;
}

.store-panel-product-image-container {
  display: flex;
  width: 100%;
  height: 12rem;
  align-items: center;
  justify-content: center;
}

.store-panel-product-image {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
