@import '../../../../config/colors.css';

/* SMALL SIZE  */
/* SMALL SIZE  */
/* SMALL SIZE  */
/* SMALL SIZE  */

.store-productsmall {
  max-height: 100%;
  margin: 1rem;
  min-width: 17rem;
  max-width: 17rem;
  transition: all 0.5s linear;
}

.store-productsmall:hover {
  border-radius: 2rem;
  transition: all 0.5s linear;
  padding: 1rem;
}

.store-product-textsmall {
  font-family: 'MyFontBook';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--lopTextDark);
  padding-inline: 0rem;
  background-color: transparent;
}

.store-product-text-infosmall {
  font-size: 1rem;
  font-weight: bolder;
  color: var(--lop-store6);
  background-color: transparent;
}

.store-product-text-pricesmall {
  font-weight: bold;
  font-size: 1.2rem;
  color: black;
  background-color: transparent;
}

.store-product-image-containersmall {
  display: flex;
  width: 100%;
  height: 12.5rem;
  align-items: center;
  justify-content: center;
}
.store-product-imagesmall {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.icons-containersmall {
  display: flex;
  padding-inline: 0.5rem;
}

.fa-shopping-bag.small,
.fa-heart.small {
  color: var(--lop-store6) !important;
  padding-right: 1rem;
  font-size: 1.5rem;
}

/* MEDDIUM SIZE  */
/* MEDDIUM SIZE  */
/* MEDDIUM SIZE  */
/* MEDDIUM SIZE  */

.store-productmedium {
  margin: 2rem;
  min-width: 20rem;
  max-width: 20rem;
  transition: all 0.5s linear;
}
.store-productmedium:hover {
  transition: all 0.5s linear;
  padding: 1rem;
}
.store-product-textmedium {
  font-family: 'MyFontBook';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  font-size: 1rem;
  color: var(--lopTextDark);
  padding-inline: 1rem;
  background-color: transparent;
}

.store-product-text-infomedium {
  font-size: 0.8rem;
  font-weight: bolder;
  color: var(--lop-store6);
  padding-inline: 0rem;
  background-color: transparent;
}

.store-product-text-pricemedium {
  font-weight: bold;
  font-size: 1.2rem;
  color: black;
  padding-inline: 1rem;
  background-color: transparent;
}

.store-product-image-containermedium {
  display: flex;
  width: 100%;
  height: 20rem;
  align-items: center;
  justify-content: center;
}
.store-product-imagemedium {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.icons-containermedium {
  display: flex;
  padding-inline: 0.5rem;
}

.fa-shopping-bag.medium,
.fa-heart.medium {
  color: var(--lop-store6) !important;
  padding-right: 1rem;
  font-size: 1.5rem;
}

/* LARGE SIZE  */
/* LARGE SIZE  */
/* LARGE SIZE  */
/* LARGE SIZE  */

.store-productlarge {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 20rem;
  height: 32rem;
  margin-left: 1rem;

  transition: all 0.5s linear;
}

.store-productlarge:hover {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
  transition: all 0.5s linear;
}

.store-product-textlarge {
  display: flex;
  font-family: 'MyFontBook';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: bold;
  font-size: 1rem;
  color: var(--lopTextDark);
  padding-inline: 1rem;
  background-color: transparent;
}

.store-product-text-infolarge {
  font-size: 1.3rem;
  font-weight: bolder;
  color: var(--lop-store6);
  padding-inline: 1rem;
  background-color: transparent;
}

.store-product-text-pricelarge {
  font-weight: bold;
  font-size: 1.2rem;
  color: black;
  padding-inline: 1rem;
  background-color: transparent;
}

.store-product-image-containerlarge {
  display: flex;
  height: 15rem;
  width: auto;
  align-items: center;
  justify-content: center;
}
.store-product-imagelarge {
  display: flex;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.icons-containerlarge {
  display: flex;
  padding-left: 0.5rem;
}

.fa-shopping-baglarge,
.fa-heartlarge {
  color: var(--lop-store2) !important;
  padding-right: 1rem !important;
  font-size: 1.5rem !important;
}
