@import '../config/colors.css';

.point-seccao {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 0 2rem 0;
  text-align: center;
}

.point-texto {
  display: flex;
  padding-inline: 10rem;
}
