@import '../config/colors.css';

.quem-somos-seccao {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 0 0 0;
  text-align: center;
  color: var(--lopTextDark);
}

.quem-somos-text-header .h2 {
  display: flex;

  justify-content: center;
  color: #222f36;
}

.quem-somos-text {
  display: flex;
  padding-inline: 10rem;
  align-self: center;
  text-align: left;
  color: var(--lopTextDark);
}
.quem-somos-mapa-container {
  padding-block: 2rem;
}
.quem-somos-mapa {
  background-color: #222f36 !important;
  max-height: 30rem;
}
